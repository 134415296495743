import { methodEnum } from "../utils/constant";
import request from "../utils/request";

const SearchUrl = "/search/";
const CollectionsUrl = "/home/collections/";
const ReportsUrl = "/home/reports/";
const PostsUrl = "/home/posts/";
const PostContentsUrl = "/home/postcontents/";
const CommonImagesUrl = "/home/commonimages/";
const AboutContentUrl = "/home/aboutuscontents/"
const GetAboutData = async () => {
  return await request({
    url: `${AboutContentUrl}`,
    method: methodEnum.GET,
  });
};
const GetSearchData = async (keyword) => {
  return await request({
    url: `${SearchUrl}?keywords=${keyword}`,
    method: methodEnum.GET,
  });
};

const GetHomeCollectionsData = async () => {
  return await request({
    url: `${CollectionsUrl}`,
    method: methodEnum.GET,
  });
};

const GetHomeReportsData = async () => {
  return await request({
    url: `${ReportsUrl}`,
    method: methodEnum.GET,
  });
};

const GetHomePostsData = async (keyword) => {
  return await request({
    url: `${PostsUrl}?label=${keyword}`,
    method: methodEnum.GET,
  });
};


const GetHomePostsContentsData = async () => {
  return await request({
    url: `${PostContentsUrl}`,
    method: methodEnum.GET,
  });
};


const GetCommonImagesData = async () => {
  return await request({
    url: `${CommonImagesUrl}`,
    method: methodEnum.GET,
  });
};


export {
  GetSearchData,
  GetHomeCollectionsData,
  GetHomePostsData,
  GetHomeReportsData,
  GetCommonImagesData,
  GetHomePostsContentsData,
  GetAboutData
};
