import React, { Fragment, useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
// images --------------------------------------
import bannerImg from "../images/banners/aboutus-banner.png";
import img1 from '../images/about/img1.gif';
import img2 from '../images/about/img2.gif';
import img3 from '../images/about/img3.gif';
import img4 from '../images/about/img4.gif';
import img5 from '../images/about/img5.gif';
import img6 from '../images/about/img6.gif';
import img7 from '../images/about/img7.gif';
import { Link } from 'react-router-dom';
import { GetAboutData } from '../api/home_api';

function About() {
    const [path, setPath] = useState('');
    const [aboutContent, setAboutContent] = useState(null);
    
    const localContent = [
        {
            title: 'Confused with too many reviews and opinions?',
            description: 'E-bits to the save! We are the games, podcasts, movies, and TV recommender system with a bird eye view on every popular online content and help you choose the best.',
            img: img1,
        },
        {
            title: 'EQbits to the Rescue!!!',
            description: 'Our AI, NLP algorithms collect and process the data, comments, reactions, sentiments from real users all over the world to give you a single score, so you can choose wisely.',
            img: img2,
            link: '/ott/content?layout=list',
            button: 'Check out E-bits Score'
        },
        {
            title: 'Explore Tailormade Collections and Blogs',
            description: 'Compare what\'s new this week, what\'s great this year, and all-time favorites. Finding quality entertainment has never been so easy.',
            img: img3,
            link: `/${path}`,
            button: 'Explore Collections'
        },
        {
            title: 'User Ratings',
            description: 'We value our users\' opinions. We\'ve streamlined our voting process and elevated the status of the average user score across the site.',
            img: img4,
            link: '/ott/content',
            button: 'Rate your favourite Content'
        },
        {
            title: 'Critic Reviews & Ratings',
            description: 'We provide you with all the critic reviews and ratings from renowned media sources to help you choose the content.',
            img: img5,
        },
        {
            title: 'Promote to the perfect audience',
            description: 'Want to promote your channel for movies, TV, games, or podcasts? E-bits reaches out to a wider spectrum of viewers.',
            img: img6,
            link: '/contact-us',
            button: 'Contact us for more'
        },
        {
            title: 'Events, Interviews & Articles',
            description: 'We provide you with all the critic reviews and ratings from renowned media sources to help you choose the content.',
            img: img7,
            link: '/blogs',
            button: 'Explore'
        }
    ];

    useEffect(() => {
        const paths = ['ott', 'games', 'podcast', 'books'];
        const randomIndex = Math.floor(Math.random() * paths.length);
        const randomPath = paths[randomIndex];
        setPath(randomPath);
        fetchAboutData();
    }, []); // Empty dependency array to run this effect only once on component mount
    
    const fetchAboutData = async () => {
        try {
            let res = await GetAboutData();
            setAboutContent(res.data);
        } catch (error) {
            console.error('Failed to fetch about content', error);
        }
    };

    return (
        <Fragment>
            <Navbar />
            <div className='aboutus-section'>
                <div className='pb-section'>
                    <div className='pb-image'>
                        <img className='img-fit' src={aboutContent?.banner?.image || bannerImg} alt="About E-bits" />
                    </div>
                    <div className='pb-content'>
                        <h1 className='pb-title'>{aboutContent?.banner?.title}</h1>
                        <h6 className='pb-subtitle'>{aboutContent?.banner?.subtitle}</h6>
                    </div>
                </div>

                <div className='aboutus-container'>
                {(aboutContent?.sections || localContent).map((section, i) => (
                    <div className='about-card' key={i}>
                        {i % 2 === 0 ? (
                            <>
                                <div className='about-content'>
                                    <h4 className='about-content-title'>{section?.title || localContent[i]?.title}</h4>
                                    <p className='about-description'>{section?.description || localContent[i]?.description}</p>
                                    {(section?.button || localContent[i]?.button) && (
                                        <Link
                                            to={section?.link || localContent[i]?.link} 
                                            className='button'>
                                            {section?.button || localContent[i]?.button}
                                        </Link>
                                    )}
                                </div>
                                <div className='about-image'>
                                    <img src={section?.image || localContent[i]?.img} alt='' />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='about-image'>
                                    <img src={section?.image || localContent[i]?.img} alt='' />
                                </div>
                                <div className='about-content'>
                                    <h4 className='about-content-title'>{section?.title || localContent[i]?.title}</h4>
                                    <p className='about-description'>{section?.description || localContent[i]?.description}</p>
                                    {(section?.button || localContent[i]?.button) && (
                                        <Link
                                            to={section?.link || localContent[i]?.link} 
                                            className='button'>
                                            {section?.button || localContent[i]?.button}
                                        </Link>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                ))}

                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default About;
